import React from 'react';
import Box from '@webAtoms/Box/Box';
import * as styles from './card.module.scss';
import { Link } from 'gatsby';

export type CardProps = {
  className?: string;
  children: React.ReactNode;
  to?: string;
};

const Card: React.FC<CardProps> = ({ children, className, to }) => (
  <Box className={styles.card}>
    {to ? (
      <Link to={to} className={className}>
        <Box className={styles.cardInner}>{children}</Box>
      </Link>
    ) : (
      <Box className={styles.cardInner}>{children}</Box>
    )}
  </Box>
);

export default Card;
